@font-face {
    font-family: "CRACKERS";
    src: url("assets/polices/crackers_brusher_slant.otf");
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url("assets/polices/SourceSansPro-Regular.otf");
}

.hide-after::after {
    display: none;
}
button.tox-tbtn{
    width: auto !important;
    padding: 5px !important;
}

/*overwrite height by default (500px !important)*/
div.tox:nth-child(9) {
    height: fit-content !important;
}

.section table:not([cellpadding]) td, .section table:not([cellpadding]) th {
    padding: 0;
}

.tooltip-inner {
    max-width: 100%;
}
.color-select2-black .select2-container--bootstrap5 .select2-selection--single .select2-selection__rendered {
    color: black;
}
.header .header-brand{
    border-bottom: none;
}

/*.imagePublicationPreview{*/
/*    background-size: cover!important;*/
/*}*/

/*#cropper-preview-lg img{*/
/*    mask-image: linear-gradient(to top, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 80%);*/
/*    -webkit-mask-image: linear-gradient(to top, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 80%);*/
/*}*/
#gradienImgIphone{
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 20%, rgba(255,255,255,1) 100%);
    z-index: 2;
}
.phone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 302px;
    background: #1a1a58;
    border-radius: 30px;
    padding: 10px;
    z-index: 2;
}
.phone-mirror {
    position: relative;
    width: 159px;
    height: 283px;
    background-color: rgb(26, 25, 25);
    border-radius: 30px;
    z-index: 2;
}
.topWrapper {
    position: relative;
    border-radius: 30px;
    height: 20px;
    background-color: #1a1a58;
    z-index: 2;
}
.line-rec {
    position: relative;
    width: 20px;
    height: 6px;
    margin: 0px auto 0 auto;
    float: left;
    left: 20%;
    top: 4px;
    background-color: rgb(83, 83, 82);
    border-radius: 5px;
    border: 1px solid rgb(214, 214, 214);
}
.camera {
    position: relative;
    float: left;
    margin-right: 8px;
    left: 18%;
    width: 11px;
    height: 11px;
    top: 1px;
    background-color: rgb(83, 83, 82);
    border-radius: 30px;
    border: 1px solid rgb(214, 214, 214);
}

.characters {
    list-style: none;
    padding-left: 0;
}

.characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
}

.characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
}
table.dataTable > thead .sorting_asc::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='white'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.42111 2.06463C4.22088 1.96161 3.9637 1.9809 3.78597 2.12863L0.177181 5.12847C-0.046034 5.31402 -0.0602611 5.63049 0.145404 5.83532C0.351069 6.04015 0.698744 6.05578 0.921959 5.87023L4.14137 3.19406L7.06417 5.84414C7.27904 6.03896 7.62686 6.03835 7.84105 5.84278C8.05524 5.64721 8.05469 5.33073 7.83982 5.13591L4.54449 2.14806C4.50704 2.1141 4.46541 2.08629 4.42111 2.06463Z'/%3e%3c/svg%3e");
}
table.dataTable > thead .sorting_desc::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='white'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.42111 5.93537C4.22088 6.03839 3.9637 6.0191 3.78597 5.87137L0.177181 2.87153C-0.046034 2.68598 -0.060261 2.36951 0.145404 2.16468C0.351069 1.95985 0.698744 1.94422 0.921959 2.12977L4.14137 4.80594L7.06417 2.15586C7.27904 1.96104 7.62686 1.96165 7.84105 2.15722C8.05524 2.35279 8.05469 2.66927 7.83982 2.86409L4.54449 5.85194C4.50704 5.8859 4.46541 5.91371 4.42111 5.93537Z'/%3e%3c/svg%3e");
}
.reponsiveMultipleSelect .select2-selection{
    height: fit-content !important;
}